import cn from 'classnames';
import React from 'react';

import GirlBackground from '@app/assets/images/auth_girl_background.png';
import CirclesBackground from '@app/assets/images/login_left_background.png';
import Metadata from '@app/components/Metadata';
import { PageHeaderLogo } from '@app/components/PageHeaderLogo';
import { PRIVACY_POLICY, TERMS_OF_USE } from '@app/constants/externalLinks';
import { useSaveReferralToLocalStorage } from '@app/features/login/hooks/useSaveReferralToLocalStorage';
import { Button, LinkWrapper } from '@app/ui-kit';

import s from './LoginPage.module.scss';

const LoginPage = () => {
  useSaveReferralToLocalStorage();

  return (
    <div className={s.root}>
      <Metadata title="Escher - Login" />
      <div
        style={{ backgroundImage: `url(${CirclesBackground.src})` }}
        className={cn(s.img, s.left)}
      >
        <PageHeaderLogo />

        <div className={s.loginSection}>
          <div className={s.loginSectionMaintenance}>
            <div className={s.maintenance}>
              A new <span className={s.escher}>Escher</span> is coming soon...
            </div>
          </div>
        </div>

        <div className={s.footer}>
          <LinkWrapper isExternal href={PRIVACY_POLICY}>
            <Button view="tertiary1">Privacy Policy</Button>
          </LinkWrapper>
          <LinkWrapper isExternal href={TERMS_OF_USE}>
            <Button view="tertiary1">Terms of Use</Button>
          </LinkWrapper>
        </div>
      </div>
      <div
        style={{ backgroundImage: `url(${GirlBackground.src})` }}
        className={s.img}
      />
    </div>
  );
};

export default LoginPage;
